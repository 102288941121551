import React from "react";
import JSONP from "jsonp";
import PropTypes from "prop-types";
import deburr from "lodash/deburr";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";

import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import blue from '@material-ui/core/colors/blue';

const inputStyles = theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: '100%'
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4
  },
  searchIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 8,
    color: blue[400]
  }
});

class CustomizedInputBase extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    const {classes} = this.props;
    const { ref, ...other } = this.props.inputProps;

    return (
      <Paper className={classes.root} elevation={1}>
        <div className={classes.searchIcon} aria-label="Search">
          <SearchIcon />
        </div>
        <Divider className={classes.divider} />
        <InputBase
          className={classes.input}
          inputRef={ref}
          {...other}
        />
      </Paper>
    )
  }
}

CustomizedInputBase.propTypes = {
  classes: PropTypes.object.isRequired
};

const SearchInput = withStyles(inputStyles)(CustomizedInputBase);

/**
 * React-Autosuggest prop methods.
 */

function renderInputComponent(inputProps) {
  return (
    <SearchInput inputProps={inputProps} />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);
  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
              <strong key={String(index)} style={{ fontWeight: 300 }}>
                {part.text}
              </strong>
            )
        )}
      </div>
    </MenuItem>
  );
}

function fetchSuggestions(query) {
  if (query === "") {
    return [];
  }
  const googleAutoSuggestURL =
    "//suggestqueries.google.com/complete/search?client=youtube&ds=yt&q=";
  const url = googleAutoSuggestURL + query;
  return new Promise((resolve, reject) => {
    JSONP(url, (err, data) => {
      if (err) {
        return reject(err);
      }
      return resolve(data);
    });
  })
    .then(res => res[1].map(result => ({ label: result[0] })))
    .catch(err => console.error(err));
}

async function getSuggestions(value) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  const suggestions = await fetchSuggestions(value);
  return inputLength === 0 ? [] : suggestions.slice(0, 5);
}

function getSuggestionValue(suggestion) {
  return suggestion.label;
}

const styles = theme => ({
  root: {
    maxWidth: 630,
    flexGrow: 1
  },
  fullWidth: {
    width: '100%'
  },
  container: {
    position: "relative"
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: "2px",
    left: 0,
    right: 0,
    borderRadius: "4px"
  },
  suggestion: {
    display: "block",
    '&$li': {
      paddingTop: '4px'
    }
  },
  li: {},
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  },
  divider: {
    height: theme.spacing.unit * 2
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});

/**
 * Custom Search Autosuggest component.
 */
class IntegrationAutosuggest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: props.query  || '',
      suggestions: []
    }
  }
  
  handleSuggestionsFetchRequested = async ({ value, reason }) => {
    if (reason === 'input-changed') {
      this.setState({
        suggestions: await getSuggestions(value)
      });
    }
    
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  handleChange = name => (event, { newValue }) => {
    this.setState({
      [name]: newValue
    });
  };

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      return this.props.onEnterKey(this.state.query);
    }
  }

  render() {
    const { classes, fullWidth } = this.props;
    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue,
      renderSuggestion,
      onEnterKey: this.handleSearchEnter
    };
    return (
      <div className={fullWidth ? classes.fullWidth : classes.root}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            placeholder: "Search...",
            value: this.state.query,
            onChange: this.handleChange("query"),
            onKeyDown: this.handleKeyDown,
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion
          }}
          renderSuggestionsContainer={options => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
        />
      </div>
    );
  }
}

IntegrationAutosuggest.propTypes = {
  classes: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
  onEnterKey: PropTypes.func.isRequired,
  query: PropTypes.string,
};

IntegrationAutosuggest.defaultProps = {
  fullWidth: false,
  query: ''
}

export default withStyles(styles)(IntegrationAutosuggest);
