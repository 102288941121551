import React from "react"
import { withStyles } from "@material-ui/core/styles"
import {Grid} from "@material-ui/core"

import SearchAutosuggest from "../components/search-autosuggest"
import SEO from "../components/seo"

import backgroundImage from "../images/background.jpg"

const styles = theme => ({
  root: {
    minHeight: '100vh',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    paddingBottom: 0,
  },
  container: {
    minHeight: '100vh',
    flexGrow: 1,
    padding: 20,
  },
});

class IndexPage extends React.Component {

  get URLParams() {
    return new URLSearchParams(this.props.location.search);
  }

  handleSearchChange = (query) => {
    var p = this.URLParams;
    p.set('q', query);
    return this.props.navigate(`/search/?${p.toString()}`);
  }
  
  render() {
    const {classes} = this.props;
    return (
      <div className={classes.root}>
        <SEO title='Index' keywords={['search', 'web search']} />
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.container}
        >
          <SearchAutosuggest
            onEnterKey={this.handleSearchChange}
          />
        </Grid>
      </div>     
    );
  }
}

export default withStyles(styles)(IndexPage);
